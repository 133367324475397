/* NodeWidget Style*/
.title {
  font-weight: bold;
  font-size: medium;
}

.subtitle {
  font-style: italic;
}

.subtitleItem {
  padding: 5px !important;
  font-size: small;
}

.bodyStyle {
  background-color: white;
}

/* Link style */
g.srd-default-link path {
  stroke: black;
  stroke-width: 3
}

circle.point.srd-default-link__point {
  fill: rgba(0,192,255,1);
}

/*change color of the boarder of the selected node*/
div.srd-node--selected>*{
  border-color:rgb(255,64,129) !important;
}

.backdropRestrictedInsideElement {
  width: 100%;
  height: 100%;
  top: unset !important;
  bottom: unset !important;
  left: unset !important;
  right: unset !important;
  z-Index: -1;
}

.canvasPaper {
  background-color: #3c3c3c !important;
  background-image: linear-gradient(0deg, transparent 24%, rgba(255, 255, 255, 0.05) 25%, rgba(255, 255, 255, 0.05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgba(255, 255, 255, 0.05) 25%, rgba(255, 255, 255, 0.05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05) 76%, transparent 77%, transparent);
  background-size: 50px 50px;
}

/* Transitions & Animations */
.fadeInOutInitHidden {
  opacity: 0;
  height: 0px;
}
.fadeInOutInitHidden-enter {
  opacity: 0;
  max-height: 0px;
}
.fadeInOutInitHidden-enter-active {
  opacity: 1;
  transition: all 500ms ease-in;
}
.fadeInOutInitHidden-enter-done {
  opacity: 1;
}
.fadeInOutInitHidden-exit {
  opacity: 1;
}
.fadeInOutInitHidden-exit-active {
  opacity: 0;
  max-height: 0px;
  transition: all 500ms ease-out;
}
.fadeInOutInitHidden-exit-done {
  opacity: 0;
  max-height: 0px;
}

/* Transitions & Animations */
.nodeSidePanelSlideInOutInitHidden {
  width: 0px;
  margin-left: 0px;
}
.nodeSidePanelSlideInOutInitHidden-enter {
  width: 0px;
  margin-left: 0px;
}
.nodeSidePanelSlideInOutInitHidden-enter-active {
  width: 250px;
  margin-left: 5px;
  transition: all 200ms ease-in;
}
.nodeSidePanelSlideInOutInitHidden-enter-done {
  width: 250px;
  margin-left: 5px;
}
.nodeSidePanelSlideInOutInitHidden-exit {
  width: 250px;
  margin-left: 5px;
}
.nodeSidePanelSlideInOutInitHidden-exit-active {
  width: 0px;
  margin-left: 0px;
  transition: all 200ms ease-out;
}
.nodeSidePanelSlideInOutInitHidden-exit-done {
  width: 0px;
  margin-left: 0px;
}


/* Animation for hidden/showing Checkboxes for mappingProject tables */
.revealInOutInitHidden {
  opacity: 1;
}
.revealInOutInitHidden-enter {
  opacity: 0;
}
.revealInOutInitHidden-enter-active {
  opacity: 1;
  transition: all 200ms ease-in-out;
}
.revealInOutInitHidden-enter-done {
  opacity: 1;
}
.revealInOutInitHidden-exit {
  opacity: 1;
}
.revealInOutInitHidden-exit-active {
  opacity: 0;
  transition: all 200ms ease-out;
}
.revealInOutInitHidden-exit-done {
  opacity: 0;
}

/* Animation for hidden/showing Button On Project Table top toolbar button */
.revealToolbarButtonInOutIniShown {
  opacity: 1;
  width: 40px;
}
.revealToolbarButtonInOutIniShown-enter {
  opacity: 0;
  width: 0px;
}
.revealToolbarButtonInOutIniShown-enter-active {
  opacity: 1;
  width: 40px;
  transition: all 200ms ease-in-out;
}
.revealToolbarButtonInOutIniShown-enter-done {
  opacity: 1;
  width: 40px;
}
.revealToolbarButtonInOutIniShown-exit {
  opacity: 1;
  width: 40px;
}
.revealToolbarButtonInOutIniShown-exit-active {
  opacity: 0;
  width: 0px;
  transition: all 200ms ease-out;
}
.revealToolbarButtonInOutIniShown-exit-done {
  opacity: 0;
  width: 0px;
}


/* not used */
.revealToolBoxInOutInitHidden {
  opacity: 0;
}
.revealToolBoxInOutInitHidden-enter {
  opacity: 0;
}
.revealToolBoxInOutInitHidden-enter-active {
  opacity: 1;
  transition: all 400ms ease-in-out;
}
.revealToolBoxInOutInitHidden-enter-done {
  opacity: 1;
}
.revealToolBoxInOutInitHidden-exit {
  opacity: 1;
}
.revealToolBoxInOutInitHidden-exit-active {
  opacity: 0;
  transition: all 400ms ease-out;
}
.revealToolBoxInOutInitHidden-exit-done {
  opacity: 0;
}







/* Animation for hidden/showing expansion Panel Summary when the panel expands or collapsed - Init Collapsed*/
.hideExpansionPanelSummaryInOutIniShown {
  opacity: 1;
  overflow: hidden;
}
.hideExpansionPanelSummaryInOutIniShown-enter {
  opacity: 1;
  max-height: 2500px;
}
.hideExpansionPanelSummaryInOutIniShown-enter-active {
  opacity: 0;
  max-height: 0px;
  transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out 300ms;
}
.hideExpansionPanelSummaryInOutIniShown-enter-done {
  opacity: 0;
  max-height: 0px;
}
.hideExpansionPanelSummaryInOutIniShown-exit {
  opacity: 0;
  max-height: 0px;
}
.hideExpansionPanelSummaryInOutIniShown-exit-active {
  opacity: 1;
  max-height: 2500px;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out 300ms;
}
.hideExpansionPanelSummaryInOutIniShown-exit-done {
  opacity: 1;
  max-height: 2500px;
}

/* Animation for hidden/showing expansion Panel Summary when the panel expands or collapsed - Init Expanded*/
.hideExpansionPanelSummaryInOutIniHidden {
  opacity: 0;
  overflow: hidden;
  max-height: 0px;
}
.hideExpansionPanelSummaryInOutIniHidden-enter {
  opacity: 1;
  max-height: 800px;
}
.hideExpansionPanelSummaryInOutIniHidden-enter-active {
  opacity: 0;
  max-height: 0px;
  transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out 300ms;
}
.hideExpansionPanelSummaryInOutIniHidden-enter-done {
  opacity: 0;
  max-height: 0px;
}
.hideExpansionPanelSummaryInOutIniHidden-exit {
  opacity: 0;
  max-height: 0px;
}
.hideExpansionPanelSummaryInOutIniHidden-exit-active {
  opacity: 1;
  max-height: 800px;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out 300ms;
}
.hideExpansionPanelSummaryInOutIniHidden-exit-done {
  opacity: 1;
  max-height: 800px;
}


/* Animation for hidding / showing Intermediates */
.hideShowIntermediates {
  opacity: 0;
  max-height: 0px;
}
.hideShowIntermediates-enter {
  opacity: 0;
  max-height: 0px;
}
.hideShowIntermediates-enter-active {
  opacity: 1;
  max-height: 100%;
  transition: all 500ms ease-in;
}
.hideShowIntermediates-enter-done {
  opacity: 1;
  max-height: 100%;
}
.hideShowIntermediates-exit {
  opacity: 1;
  max-height: 100%;
}
.hideShowIntermediates-exit-active {
  opacity: 0;
  max-height: 0px;
  transition: all 500ms ease-out;
}
.hideShowIntermediates-exit-done {
  opacity: 0;
  max-height: 0px;
}

/* Animation for hidding / showing Links */
.hideShowLink {
  opacity: 0;
  /*height:100%;*/
  max-height: 0px;
}
.hideShowLink-enter {
  opacity: 0;
  max-height: 0px;
}
.hideShowLink-enter-active {
  opacity: 1;
  max-height: 100%;
  transition: all 400ms ease-in;
}
.hideShowLink-enter-done {
  opacity: 1;
  max-height: 100%;
}
.hideShowLink-exit {
  opacity: 1;
  max-height: 100%;
}
.hideShowLink-exit-active {
  opacity: 0;
  max-height: 0px;
  transition: all 400ms ease-out;
}
.hideShowLink-exit-done {
  opacity: 0;
  max-height: 0px;
}

/* Increase size when the additional is added */
.increaseSpaceWhenConstExprIsAdded {
  max-width: 50% !important;
  flex-basis: 50% !important;
}
.increaseSpaceWhenConstExprIsAdded-enter {
  max-width: 50% !important;
  flex-basis: 50% !important;
}
.increaseSpaceWhenConstExprIsAdded-enter-active {
  max-width: 67% !important;
  flex-basis: 67% !important;
  transition: all 500ms ease-in;
}
.increaseSpaceWhenConstExprIsAdded-enter-done {
  max-width: 67% !important;
  flex-basis: 67% !important;
}
.increaseSpaceWhenConstExprIsAdded-exit {
  max-width: 67% !important;
  flex-basis: 67% !important;
}
.increaseSpaceWhenConstExprIsAdded-exit-active {
  max-width: 50% !important;
  flex-basis: 50% !important;
  transition: all 500ms ease-out;
}
.increaseSpaceWhenConstExprIsAdded-exit-done {
  max-width: 50% !important;
  flex-basis: 50% !important;
}

/* decrease size when the additional is added */
.decreaseSpaceWhenConstExprIsAdded {
  max-width: 50% !important;
  flex-basis: 50% !important;
}
.decreaseSpaceWhenConstExprIsAdded-enter {
  max-width: 50% !important;
  flex-basis: 50% !important;
}
.decreaseSpaceWhenConstExprIsAdded-enter-active {
  max-width: 33% !important;
  flex-basis: 33% !important;
  transition: all 500ms ease-in;
}
.decreaseSpaceWhenConstExprIsAdded-enter-done {
  max-width: 33% !important;
  flex-basis: 33% !important;
}
.decreaseSpaceWhenConstExprIsAdded-exit {
  max-width: 33% !important;
  flex-basis: 33% !important;
}
.decreaseSpaceWhenConstExprIsAdded-exit-active {
  max-width: 50% !important;
  flex-basis: 50% !important;
  transition: all 500ms ease-out;
}
.decreaseSpaceWhenConstExprIsAdded-exit-done {
  max-width: 50% !important;
  flex-basis: 50% !important;
}

/* Transitions & Animations - resizing pannel */
.sectionCollapseInitShown {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
}
.sectionCollapseInitShown-enter {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
}
.sectionCollapseInitShown-enter-active {
  width: 0px;
  max-width: 0px;
  min-width: 0px;
  transition: all 200ms ease-in;
}
.sectionCollapseInitShown-enter-done {
  width: 0px;
  max-width: 0px;
  min-width: 0px;
}
.sectionCollapseInitShown-exit {
  width: 0px;
  max-width: 0px;
  min-width: 0px;
}
.sectionCollapseInitShown-exit-active {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  transition: all 200ms ease-out;
}
.sectionCollapseInitShown-exit-done {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
}





.sectionCollapseInitShown350 {
  width: 350px;
  max-width: 350px;
  min-width: 350px;
}
.sectionCollapseInitShown350-enter {
  width: 350px;
  max-width: 350px;
  min-width: 350px;
}
.sectionCollapseInitShown350-enter-active {
  width: 0px;
  max-width: 0px;
  min-width: 0px;
  transition: all 200ms ease-in;
}
.sectionCollapseInitShown350-enter-done {
  width: 0px;
  max-width: 0px;
  min-width: 0px;
}
.sectionCollapseInitShown350-exit {
  width: 0px;
  max-width: 0px;
  min-width: 0px;
}
.sectionCollapseInitShown350-exit-active {
  width: 350px;
  max-width: 350px;
  min-width: 350px;
  transition: all 200ms ease-out;
}
.sectionCollapseInitShown350-exit-done {
  width: 350px;
  max-width: 350px;
  min-width: 350px;
}

/* Moving Button left when the father-panel is collapsed */
.moveLeftWhenPanelCollapsed {
  margin-left: 32px;
  position: absolute;
  top: 20px;
}
.moveLeftWhenPanelCollapsed-enter {
  margin-left: 32px;
  position: absolute;
  top: 20px;
}
.moveLeftWhenPanelCollapsed-enter-active {
  margin-left: -20px;
  position: absolute;
  top: 20px;
  transition: all 200ms ease-in;
}
.moveLeftWhenPanelCollapsed-enter-done {
  margin-left: -20px;
  position: absolute;
  top: 20px;
}
.moveLeftWhenPanelCollapsed-exit {
  margin-left: -20px;
  position: absolute;
  top: 20px;
}
.moveLeftWhenPanelCollapsed-exit-active {
  margin-left: 32px;
  position: absolute;
  top: 20px;
  transition: all 200ms ease-out;
}
.moveLeftWhenPanelCollapsed-exit-done {
  margin-left: 32px;
  position: absolute;
  top: 20px;
}

/* Moving Button left when the father-panel is collapsed */
.moveRightWhenPanelCollapsed {
  left: 324px;
  position: absolute;
  top: 20px;
}
.moveRightWhenPanelCollapsed-enter {
  left: 324px;
  position: absolute;
  top: 20px;
}
.moveRightWhenPanelCollapsed-enter-active {
  left: 80px;
  position: absolute;
  top: 20px;
  transition: all 200ms ease-in;
}
.moveRightWhenPanelCollapsed-enter-done {
  left: 80px;
  position: absolute;
  top: 20px;
}
.moveRightWhenPanelCollapsed-exit {
  left: 80px;
  position: absolute;
  top: 20px;
}
.moveRightWhenPanelCollapsed-exit-active {
  left: 324px;
  position: absolute;
  top: 20px;
  transition: all 200ms ease-out;
}
.moveRightWhenPanelCollapsed-exit-done {
  left: 324px;
  position: absolute;
  top: 20px;
}

.marginLeftWhenPanelCollapsed {
  margin-left: 0px;
}
.marginLeftWhenPanelCollapsed-enter {
  margin-left: 0px;
}
.marginLeftWhenPanelCollapsed-enter-active {
  margin-left: 56px;
  transition: all 200ms ease-in;
}
.marginLeftWhenPanelCollapsed-enter-done {
  margin-left: 56px;
}
.marginLeftWhenPanelCollapsed-exit {
  margin-left: 56px;
}
.marginLeftWhenPanelCollapsed-exit-active {
  margin-left: 0px;
  transition: all 200ms ease-out;
}
.marginLeftWhenPanelCollapsed-exit-done {
  margin-left: 0px;
}







/* React-Dock - Overwriting */
/*
.customDockWrapper div {
  top: unset !important;
}
*/
/* filepond */
.filePondCustomLabelStyle {
  color: rgba(0, 0, 0, 0.54);
  /*font-size: 20px !important;*/
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
}

/*
.filePondCustomPanelStyle div.filepond--panel.filepond--panel-root {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
*/

/* FilePond mock files css style overwritting */
/* Hide the delete button from the mocked filepond */
.filePondCustomMock button.filepond--file-action-button.filepond--action-remove-item {
  transform: translate3d(0px, 0px, 0) scale3d(1, 1, 1);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  width: 0px;
}

/* Codemirror*/
.codemirrorAutoHeight .CodeMirror {
  border: 1px solid #eee;
  height: auto;
}

.codemirrorCustomStyle {
  /*width: 100%;*/
  flex: 1;
  /*min-height: 100%;*/
}

.codemirrorNoHeight .CodeMirror {
  height: unset;
}

.codemirrorCustomStyle div.cm-s-material.CodeMirror {
  border-radius: 5px;
}


/* Shake Animation*/
@keyframes rumble {
    0% {-webkit-transform: rotate(0deg);}
    25% {-webkit-transform: translate(2px, 0px) rotate(-3deg);}
    50% {-webkit-transform: translate(0px, -2px) rotate(0deg);}
    75% {-webkit-transform: translate(-2px, 0px) rotate(3deg);}
    100% {-webkit-transform: translate(0px, 2px) rotate(0deg);}
}

.rumble {
    animation: rumble .0625s infinite linear;
    animation-iteration-count: 25;
}

/* Fade in when adding items */
.fadeIn {
  animation: fadeInAnimation 1s linear;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeOut {
  animation: fadeOutAnimation .2s linear forwards;
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.dragFixClass {
  top: auto !important;
  left: auto !important;
}
