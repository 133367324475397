/*body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}*/


/* Authentication - starts */

/*form {
  height: 100%;
  padding: 1.5rem;
  background: #fafafa;
}*/
/*
input {
  background-color: transparent;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  color: #111;
  font-size: 1rem;
  padding: .3rem .5rem;
  margin-bottom: 1rem;
  display: block;
}*/

/*.input-error {
  border-color: #FF0000;
}*/

.rootBody {
  height: auto;
}

.error {
  color: #FF0000;
  padding: .2rem;
}

.btn {
  /*padding: .5rem 1.5rem;
  font-size: .85rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  background: #111;
  border-radius: .25rem;
  margin: 0;*/
}

/* Authentication - Ends */

/* React Router Transition - Animated Switch */
.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  left: 0px;
    top: 0px;
    width: 100%;
}
